<script setup lang="ts">
import { ref, watch } from "vue";

// Hide label while loading and show spinner while keeping width
const props = defineProps<{
  loading: boolean;
  disabled?: boolean;
  icon?: string;
  label?: string;
}>();

const button = ref();

watch(
  () => props.loading,
  (isLoading, wasLoading) => {
    if (isLoading && !wasLoading) {
      const buttonEl = button.value.$el;
      const width = buttonEl.getBoundingClientRect().width;
      buttonEl.style.width = `${width}px`;
    } else if (!isLoading) {
      button.value.$el.style.width = "";
    }
  },
);
</script>

<template>
  <Button
    ref="button"
    v-bind="{
      ...$props,
      label: loading ? '' : label,
      icon: loading ? 'pi pi-spin pi-spinner' : icon,
      disabled: loading || disabled,
    }"
  />
</template>
