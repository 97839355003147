import posthog from "posthog-js";

export function usePostHog(): { posthog: typeof posthog } {
  const POSTHOG_API_KEY = import.meta.env.VITE_POSTHOG_API_KEY;

  if (POSTHOG_API_KEY) {
    posthog.init(POSTHOG_API_KEY, {
      api_host: "https://us.i.posthog.com",
      capture_pageview: false,
    });
  }

  return {
    posthog,
  };
}
