<script setup lang="ts">
import { auth0 } from "@/auth0";
import TopBar from "@/components/common/TopBar.vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const { isAuthenticated, isLoading } = auth0;

const showMenu = ref(false);

const currentRoute = computed(() => router.currentRoute.value.name);

const links = computed(() => [
  {
    to: "pricing",
    label: "Pricing",
    isActive: currentRoute.value === "pricing",
  },
  {
    to: "contact",
    label: "Contact",
    isActive: currentRoute.value === "contact",
  },
]);
</script>

<template>
  <TopBar>
    <!-- Mobile -->
    <div class="sm:hidden flex items-center gap-2">
      <DarkModeToggler size="medium" rounded text />
      <Button
        v-if="isAuthenticated"
        as="router-link"
        size="small"
        :to="{ name: 'dashboard' }"
        class="ml-1 whitespace-nowrap"
      >
        Dashboard
      </Button>
      <Button
        v-else
        :icon="`pi ${showMenu ? 'pi-times' : 'pi-bars'}`"
        severity="secondary"
        aria-label="Menu"
        rounded
        text
        @click="showMenu = !showMenu"
      />
    </div>
    <Drawer v-model:visible="showMenu" header="Menu" position="right">
      <div class="mx-auto max-w-40 flex flex-col items-center gap-2" @click="showMenu = false">
        <Button
          v-for="link in links"
          as="router-link"
          :to="{ name: link.to }"
          :label="link.label"
          severity="secondary"
          rounded
          :text="!link.isActive"
          class="w-full"
        />
      </div>
      <template #footer>
        <div class="grid grid-cols-2 gap-2">
          <LogInButton size="small" severity="secondary" class="w-full" />
          <SignUpButton size="small" class="w-full" />
        </div>
      </template>
    </Drawer>

    <!-- Desktop -->
    <div class="hidden sm:flex items-center gap-1">
      <template v-if="!isAuthenticated">
        <Button
          v-for="link in links"
          as="router-link"
          :to="{ name: link.to }"
          :label="link.label"
          :aria-label="link.label"
          :severity="link.isActive ? 'primary' : 'secondary'"
          size="small"
          rounded
          text
          :class="{ '!h-8': link.isActive }"
        />
      </template>
      <DarkModeToggler v-tooltip.bottom="{ value: 'Switch Theme', pt: { text: '!text-sm' } }" rounded text />

      <LoadingButton v-if="isLoading" :loading="true" size="small" severity="secondary" />
      <Button
        v-else-if="isAuthenticated"
        as="router-link"
        size="small"
        :to="{ name: 'dashboard' }"
        class="ml-1 whitespace-nowrap"
      >
        Dashboard
      </Button>
      <template v-else>
        <LogInButton size="small" severity="secondary" class="ml-1 whitespace-nowrap" />
        <SignUpButton size="small" class="ml-1 whitespace-nowrap" />
      </template>
    </div>
  </TopBar>
</template>
