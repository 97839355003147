<script setup lang="ts">
import { auth0 } from "@/auth0";
import { ref } from "vue";

const props = withDefaults(
  defineProps<{
    label?: string;
    redirect?: string;
  }>(),
  {
    label: "Sign up",
    redirect: "/dashboard",
  },
);

const loading = ref(false);

const signup = async () => {
  loading.value = true;
  await auth0.loginWithRedirect({
    appState: { target: props.redirect },
    authorizationParams: {
      screen_hint: "signup",
    },
  });
};
</script>

<template>
  <LoadingButton :label="label" :loading="loading" @click="signup" />
</template>
