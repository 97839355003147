<script setup lang="ts">
import { auth0 } from "@/auth0";
import DarkModeToggler from "@/components/common/DarkModeToggler.vue";
import TopBar from "@/components/common/TopBar.vue";
import * as Sentry from "@sentry/browser";
import Button from "primevue/button";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const showMenu = ref(false);

const currentRoute = computed(() => router.currentRoute.value.name);

const links = computed(() => [
  {
    to: "videos",
    label: "Videos",
    isActive:
      (currentRoute.value as string)?.startsWith("video-") ||
      ["video", "videos"].includes(currentRoute.value as string),
  },
  {
    to: "help",
    label: "Help",
    isActive: currentRoute.value === "help",
  },
  {
    to: "billing",
    icon: "pi pi-dollar",
    label: "Billing",
    isActive: currentRoute.value === "billing",
  },
  {
    to: "account",
    icon: "pi pi-user",
    label: "Account",
    isActive: currentRoute.value === "account",
  },
]);

const onLogout = () => {
  Sentry.setUser(null);
  auth0.logout({ logoutParams: { returnTo: window.location.origin } });
};
</script>

<template>
  <TopBar :to="{ name: 'dashboard' }" border full>
    <!-- Mobile -->
    <div class="sm:hidden flex items-center gap-2">
      <DarkModeToggler size="medium" rounded text />
      <Button
        :icon="`pi ${showMenu ? 'pi-times' : 'pi-bars'}`"
        severity="secondary"
        aria-label="Menu"
        rounded
        text
        @click="showMenu = !showMenu"
      />
    </div>
    <Drawer v-model:visible="showMenu" header="Menu" position="right">
      <div class="mx-auto max-w-40 flex flex-col items-center gap-2" @click="showMenu = false">
        <Button
          v-for="link in links"
          as="router-link"
          :to="{ name: link.to }"
          :label="link.label"
          severity="secondary"
          rounded
          :text="!link.isActive"
          class="w-full"
        />
      </div>
      <template #footer>
        <Button label="Logout" severity="secondary" aria-label="Logout" rounded text class="w-full" @click="onLogout" />
      </template>
    </Drawer>

    <!-- Desktop -->
    <div class="hidden sm:flex items-center gap-1">
      <Button
        v-for="link in links"
        v-tooltip.bottom="link.icon ? { value: link.label, pt: { text: '!text-sm' } } : undefined"
        as="router-link"
        :to="{ name: link.to }"
        :icon="link.icon"
        :label="!link.icon ? link.label : undefined"
        :aria-label="link.label"
        :severity="link.isActive ? 'primary' : 'secondary'"
        size="small"
        rounded
        :text="!link.isActive"
        :class="{ '!h-8': link.isActive && !link.icon, '!size-9 mx-0.5': link.isActive && link.icon }"
      />
      <DarkModeToggler v-tooltip.bottom="{ value: 'Switch Theme', pt: { text: '!text-sm' } }" rounded text />
      <Button
        v-tooltip.bottom="{ value: 'Logout', pt: { text: '!text-sm' } }"
        icon="pi pi-power-off"
        size="small"
        severity="secondary"
        aria-label="Logout"
        rounded
        text
        @click="onLogout"
      />
    </div>
  </TopBar>
</template>
