<script setup lang="ts">
import { auth0 } from "@/auth0";
import { usePostHog } from "@/composables/posthog";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import * as Sentry from "@sentry/browser";
import { computed, watchEffect } from "vue";
import { useRoute } from "vue-router";

const { isAuthenticated, isLoading, user } = auth0;
const { posthog } = usePostHog();
const route = useRoute();

const layout = computed(() => {
  return route.meta.layout || DefaultLayout;
});

watchEffect(() => {
  if (!isLoading.value) {
    Sentry.setUser(isAuthenticated.value ? { id: user.value?.sub, email: user.value?.email } : null);

    if (isAuthenticated.value) {
      posthog.identify(user.value?.sub, { email: user.value?.email });
    }
  }
});
</script>

<template>
  <Toast />
  <component :is="layout">
    <router-view />
  </component>
</template>
