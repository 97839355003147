<script setup lang="ts">
import type { VideoConfig } from "@/api.d";
import { languages } from "@/constants";
import { useVideoStore } from "@/stores/video";
import type { FormFieldState } from "@primevue/forms";
import { Form } from "@primevue/forms";
import { useConfirm } from "primevue/useconfirm";
import { DefineComponent, computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";

const confirm = useConfirm();
const router = useRouter();
const store = useVideoStore();

const form = ref<InstanceType<DefineComponent<typeof Form>> | null>(null);

const isDeleting = ref(false);
const isGenerating = ref(false);

const isLanguageSet = computed(() => store.video?.language);
const isNoLyricsError = computed(() => store.video?.error_code === "NO_LYRICS");

const errorMessage = computed(() => {
  if (isNoLyricsError.value) {
    return "Our AI is unable to extract the lyrics of this song. Maybe the vocals are too low or the language is not recognized. We apologize for this, please try another song.";
  }

  return "There's been an error processing this video. We're sorry for the inconvenience.";
});

const initialValues = reactive<Record<string, boolean | number | number[] | string>>({
  language: store.video!.language,
});

const applyChanges = () => {
  const formData = (Object.entries(form.value!.states) as [string, FormFieldState][]).reduce(
    (data: Record<string, boolean | number | number[] | string>, [key, field]: [string, FormFieldState]) => {
      data[key as keyof VideoConfig] = field.value;
      return data;
    },
    {} as Partial<VideoConfig>,
  );
  store.generatePreview(formData);
};

const backToVideos = () => {
  router.push({ name: "videos" });
};

const confirmDelete = () => {
  confirm.require({
    message: "Are you sure you want to delete the video?",
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    rejectProps: {
      label: "Cancel",
      severity: "secondary",
      outlined: true,
    },
    acceptProps: {
      label: "Yes, delete video",
      severity: "danger",
    },
    accept: async () => {
      isDeleting.value = true;

      const deleted = await store.deleteVideo();

      if (deleted) {
        backToVideos();
      } else {
        isDeleting.value = false;
      }
    },
  });
};
</script>

<template>
  <ConfirmDialog />

  <h1 class="text-lg md:text-2xl">{{ store.video?.name }}</h1>

  <div class="mt-5">
    <SimpleCard
      v-if="isNoLyricsError && !isLanguageSet"
      icon="pi-check-square"
      title="Confirm Lyrics Language"
      centered
    >
      <p class="mt- text-center text-muted-color text-sm">
        Our AI amazing, but some it needs help to extract the lyrics.
      </p>

      <Form ref="form" :initialValues class="max-w-80 mx-auto mt-8 flex flex-col gap-5">
        <VerticalInput label="Lyrics Language" help="Please select the main language of the lyrics of your song.">
          <label for="language" class="text-sm"></label>
          <Select
            id="language"
            name="language"
            :options="languages"
            optionLabel="label"
            optionValue="value"
            placeholder="Select a language"
            class="w-full"
            filter
            :disabled="store.statusIsGenerating"
          />
        </VerticalInput>

        <div class="mt-3 text-center">
          <Button
            v-if="isGenerating"
            label="Generating..."
            icon="pi pi-spin pi-spinner"
            class="sm:w-40 !text-sm sm:!text-base !px-5 sm:!px-8"
            disabled
          />
          <Button v-else label="Confirm" class="sm:w-40 !text-sm sm:!text-base !px-5 sm:!px-8" @click="applyChanges" />
        </div>
      </Form>
    </SimpleCard>
    <SimpleCard v-else icon="pi-exclamation-triangle" title="Error Processing" centered>
      <div class="mt-1 flex justify-center">
        <Message severity="error">{{ errorMessage }}</Message>
      </div>
      <div class="mt-3 text-center text-xs text-muted-color">UUID: {{ store.video?.id }}</div>

      <Divider />

      <p class="mt-7 text-center">Feel free to send us a message:</p>
      <div class="mt-2 text-center">
        <Button
          as="router-link"
          :to="{ name: 'help', query: { video_id: store.video?.id } }"
          label="Contact Us"
          icon="pi pi-send"
          class="!text-sm sm:!text-base !px-5 sm:!px-8"
        />
      </div>

      <p class="mt-10 text-center">You can also delete this video:</p>
      <div class="mt-2 text-center pb-2">
        <LoadingButton
          label="Delete Video"
          icon="pi pi-trash"
          class="!text-sm sm:!text-base !px-5 sm:!px-8"
          severity="danger"
          :loading="isDeleting"
          :disabled="store.isRequesting"
          @click="confirmDelete"
        />
        <div class="mt-2 text-xs text-muted-color">
          Any minutes used for this video will be automatically restored to your account.
        </div>
      </div>
    </SimpleCard>

    <div class="mt-5 text-center">
      <BackButton :to="{ name: 'videos' }" label="Back to Videos" size="small" />
    </div>
  </div>
</template>
