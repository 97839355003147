import { createAuth0 } from "@auth0/auth0-vue";

// This allows calling auth0 from outside a Vue component.
// Docs: https://github.com/auth0/auth0-vue/issues/99#issuecomment-1099704276
export const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  cacheLocation: "localstorage",
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: import.meta.env.VITE_AUTH0_REDIRECT_URL,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  },
});
