<script setup lang="ts">
import VideoError from "@/components/dashboard/video/VideoError.vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";
import { useVideoStore } from "@/stores/video";
import { computed, onBeforeUnmount, onMounted, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";

const REQUEST_INTERVAL_PREVIEW = 3000; // 3 seconds
const REQUEST_INTERVAL_FULL = 5000; // 5 seconds

const route = useRoute();
const router = useRouter();
const store = useVideoStore();

const STEP_CUSTOMIZE = "video-customize";
const STEP_DOWNLOAD = "video-download";
const STEP_GENERATE = "video-generate";
const STEP_SCENES = "video-scenes";

const STEPS = [STEP_CUSTOMIZE, STEP_SCENES, STEP_GENERATE, STEP_DOWNLOAD];

const currentStep = computed(() => route.name as string);
const stepperValue = computed(() => STEPS.indexOf(currentStep.value) + 1);

let intervalStatus: NodeJS.Timeout;

const startPolling = () => {
  clearInterval(intervalStatus);

  const timePoll = store.video?.full_generation ? REQUEST_INTERVAL_FULL : REQUEST_INTERVAL_PREVIEW;
  intervalStatus = setInterval(store.refresh, timePoll);
};

const stopPolling = () => {
  clearInterval(intervalStatus);
};

watchEffect(() => {
  if (store.video?.full_generation && store.statusIsGenerating) {
    const forceStep = STEP_GENERATE;
    if (currentStep.value !== forceStep) {
      router.push({
        name: forceStep,
        params: { id: store.video.id },
      });
    }
  }

  if (store.video?.full_generation && currentStep.value === STEP_CUSTOMIZE) {
    router.push({
      name: STEP_SCENES,
      params: { id: store.video.id },
    });
  }

  if (store.statusIsGenerating && !store.isRequesting) {
    startPolling();
  } else {
    stopPolling();
  }
});

onBeforeUnmount(() => {
  clearInterval(intervalStatus);
});

onMounted(() => {
  const id = route.params.id;
  const videoId = Array.isArray(id) ? id[0] : id;

  store.loadVideo(videoId);
});
</script>

<template>
  <DashboardLayout>
    <div v-if="!store.video">
      <Loading v-if="store.isRequesting" />
      <div v-else-if="store.loadingError">
        <LoadingError />

        <div class="mt-10 text-center">
          <BackButton :to="{ name: 'videos' }" label="Back to Videos" severity="secondary" size="small" :text="false" />
        </div>
      </div>
    </div>
    <div v-else-if="store.statusIsError" class="md:w-2/3 lg:w-1/2 mx-auto pb-20">
      <VideoError />
    </div>
    <div v-else-if="store.video">
      <div class="-mt-3 mb-3">
        <Stepper :value="stepperValue" class="basis-[50rem]">
          <StepList>
            <Step :value="1" disabled>
              <span class="hidden md:inline">Customize</span>
            </Step>
            <Step :value="2" disabled>
              <span class="hidden md:inline">Edit Scenes</span>
            </Step>
            <Step :value="3" disabled>
              <span class="hidden md:inline">Generate Full Video</span>
            </Step>
            <Step :value="4" disabled>
              <span class="hidden md:inline">Download</span>
            </Step>
          </StepList>
        </Stepper>
      </div>

      <div class="pb-20 md:mx-2">
        <router-view />
      </div>
    </div>
  </DashboardLayout>
</template>
