<script setup lang="ts">
import { auth0 } from "@/auth0";
import { onMounted, ref } from "vue";

const props = defineProps<{
  auto?: boolean;
}>();

const loading = ref(false);

const login = async () => {
  loading.value = true;
  await auth0.loginWithRedirect({
    appState: { target: "/dashboard" },
  });
};

onMounted(() => {
  if (props.auto) {
    login();
  }
});
</script>

<template>
  <LoadingButton label="Log in" :loading="loading" @click="login" />
</template>
