export const fonts = [
  { label: "Condensed", value: "condensed" },
  { label: "Cursive", value: "cursive" },
  { label: "Graffiti", value: "graffiti" },
  { label: "Handwritten", value: "handwritten" },
  { label: "Historic", value: "historic" },
  { label: "Modern", value: "modern" },
  { label: "Narrow", value: "narrow" },
  { label: "Rounded", value: "rounded" },
  { label: "Tech", value: "tech" },
  { label: "Thick", value: "thick" },
  { label: "Typewriter", value: "typewriter" },
  { label: "Vinyl", value: "vinyl" },
];

export const languages = [
  // Top of the list
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" },
  // Alphabetical
  { label: "Afrikaans", value: "af" },
  { label: "Albanian", value: "sq" },
  { label: "Amharic", value: "am" },
  { label: "Arabic", value: "ar" },
  { label: "Armenian", value: "hy" },
  { label: "Assamese", value: "as" },
  { label: "Azerbaijani", value: "az" },
  { label: "Bashkir", value: "ba" },
  { label: "Basque", value: "eu" },
  { label: "Belarusian", value: "be" },
  { label: "Bengali", value: "bn" },
  { label: "Bosnian", value: "bs" },
  { label: "Breton", value: "br" },
  { label: "Cantonese", value: "yue" },
  { label: "Bulgarian", value: "bg" },
  { label: "Catalan", value: "ca" },
  { label: "Chinese", value: "zh" },
  { label: "Croatian", value: "hr" },
  { label: "Czech", value: "cs" },
  { label: "Danish", value: "da" },
  { label: "Dutch", value: "nl" },
  { label: "Estonian", value: "et" },
  { label: "Faroese", value: "fo" },
  { label: "Finnish", value: "fi" },
  { label: "French", value: "fr" },
  { label: "Galician", value: "gl" },
  { label: "Georgian", value: "ka" },
  { label: "German", value: "de" },
  { label: "Greek", value: "el" },
  { label: "Gujarati", value: "gu" },
  { label: "Haitian Creole", value: "ht" },
  { label: "Hausa", value: "ha" },
  { label: "Hawaiian", value: "haw" },
  { label: "Hebrew", value: "he" },
  { label: "Hindi", value: "hi" },
  { label: "Hungarian", value: "hu" },
  { label: "Icelandic", value: "is" },
  { label: "Indonesian", value: "id" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Javanese", value: "jv" },
  { label: "Kannada", value: "kn" },
  { label: "Kazakh", value: "kk" },
  { label: "Khmer", value: "km" },
  { label: "Korean", value: "ko" },
  { label: "Lao", value: "lo" },
  { label: "Latin", value: "la" },
  { label: "Latvian", value: "lv" },
  { label: "Lingala", value: "ln" },
  { label: "Lithuanian", value: "lt" },
  { label: "Luxembourgish", value: "lb" },
  { label: "Macedonian", value: "mk" },
  { label: "Malagasy", value: "mg" },
  { label: "Malay", value: "ms" },
  { label: "Malayalam", value: "ml" },
  { label: "Maltese", value: "mt" },
  { label: "Maori", value: "mi" },
  { label: "Marathi", value: "mr" },
  { label: "Mongolian", value: "mn" },
  { label: "Myanmar", value: "my" },
  { label: "Nepali", value: "ne" },
  { label: "Norwegian", value: "no" },
  { label: "Nynorsk", value: "nn" },
  { label: "Occitan", value: "oc" },
  { label: "Pashto", value: "ps" },
  { label: "Persian", value: "fa" },
  { label: "Polish", value: "pl" },
  { label: "Portuguese", value: "pt" },
  { label: "Punjabi", value: "pa" },
  { label: "Romanian", value: "ro" },
  { label: "Russian", value: "ru" },
  { label: "Sanskrit", value: "sa" },
  { label: "Serbian", value: "sr" },
  { label: "Shona", value: "sn" },
  { label: "Sindhi", value: "sd" },
  { label: "Sinhala", value: "si" },
  { label: "Slovak", value: "sk" },
  { label: "Slovenian", value: "sl" },
  { label: "Somali", value: "so" },
  { label: "Sundanese", value: "su" },
  { label: "Swahili", value: "sw" },
  { label: "Swedish", value: "sv" },
  { label: "Tagalog", value: "tl" },
  { label: "Tajik", value: "tg" },
  { label: "Tamil", value: "ta" },
  { label: "Tatar", value: "tt" },
  { label: "Telugu", value: "te" },
  { label: "Thai", value: "th" },
  { label: "Tibetan", value: "bo" },
  { label: "Turkish", value: "tr" },
  { label: "Turkmen", value: "tk" },
  { label: "Ukrainian", value: "uk" },
  { label: "Urdu", value: "ur" },
  { label: "Uzbek", value: "uz" },
  { label: "Vietnamese", value: "vi" },
  { label: "Welsh", value: "cy" },
  { label: "Yiddish", value: "yi" },
  { label: "Yoruba", value: "yo" },
];

export const mediums = [
  { label: "3D Render", value: "3d_render", image: "/mediums/3d_render.png" },
  { label: "Anime", value: "anime", image: "/mediums/anime.png" },
  { label: "Cartoon", value: "cartoon", image: "/mediums/cartoon.png" },
  { label: "Digital", value: "digital", image: "/mediums/digital.png" },
  { label: "Oil painting", value: "oil_painting", image: "/mediums/oil_painting.png" },
  { label: "Pencil", value: "pencil", image: "/mediums/pencil.png" },
  { label: "Photorealistic", value: "photorealistic", image: "/mediums/photorealistic.png" },
];

export const palettes = [
  // contrast colors (sorted so they are more or less pleasant one after the other)
  { colors: ["Red", "Blue"], rgb: ["#dc2626", "#77CDFF"] },
  { colors: ["Red", "Green"], rgb: ["#dc2626", "#50C878"] },
  { colors: ["Orange", "Teal"], rgb: ["#ffbf69", "#2ec4b6"] },
  { colors: ["Green", "Purple"], rgb: ["#98FB98", "#722e9a"] },
  { colors: ["Purple", "Yellow"], rgb: ["#722E9A", "#FFD93D"] },
  { colors: ["Gold", "Navy Blue"], rgb: ["#FFC300", "#0039a0"] },
  { colors: ["Pink", "Blue"], rgb: ["#F20089", "#0496FF"] },
  // blacks
  { colors: ["Red", "Black"], rgb: ["#DD0000", "#202020"] },
  { colors: ["Orange", "Black"], rgb: ["#f97316", "#202020"] },
  { colors: ["Yellow", "Black"], rgb: ["#FFFF00", "#202020"] },
  { colors: ["Green", "Black"], rgb: ["#39FF14", "#202020"] },
  { colors: ["Blue", "Black"], rgb: ["#2c98f6", "#202020"] },
  { colors: ["Purple", "Black"], rgb: ["#9b2dff", "#202020"] },
  { colors: ["Pink", "Black"], rgb: ["#ff1a9b", "#202020"] },
  // whites
  { colors: ["White", "Red"], rgb: ["#F0F0F0", "#dc2626"] },
  { colors: ["White", "Orange"], rgb: ["#F0F0F0", "#D2691E"] },
  { colors: ["White", "Yellow"], rgb: ["#F0F0F0", "#FFD700"] },
  { colors: ["White", "Green"], rgb: ["#F0F0F0", "#50C878"] },
  { colors: ["White", "Blue"], rgb: ["#F0F0F0", "#0081a7"] },
  { colors: ["White", "Purple"], rgb: ["#F0F0F0", "#722e9a"] },
  { colors: ["White", "Pink"], rgb: ["#F0F0F0", "#f20089"] },
  { colors: ["White", "Black"], rgb: ["#F0F0F0", "#202020"] },
].map(({ colors, rgb }) => ({
  value: `${colors[0]}|${colors[1]}`.toLowerCase(),
  label: `${colors[0]} & ${colors[1]}`,
  rgb,
}));

export const aspectRatios = [
  { label: "Horizontal (16:9)", value: "landscape", icon: "pi pi-desktop" },
  { label: "Vertical (9:16)", value: "portrait", icon: "pi pi-mobile" },
];

export const faqs = {
  landing: [
    {
      title: "Can I post the video to YouTube or other platforms?",
      content:
        "Yes! As long as you own the rights to the song or have permission from the artist, you can post the video on YouTube, Instagram, TikTok, or any other platform.",
    },
    {
      title: "Can I use the video for commercial purposes?",
      content:
        "Yes, you can use the video for commercial purposes (e.g., for your business or clients), as long as you own the rights to the song or have permission from the artist.<br>However, videos created during the free trial <b>cannot</b> be used for commercial purposes.<br>All paid videos are yours to keep and use freely.",
    },
    {
      title: "Can I upload a song on behalf of another artist?",
      content: "Yes, as long as you have permission from the artist.",
    },
    {
      title: "How do you generate the videos?",
      content:
        "We use commercial-grade AI models to analyze the lyrics and generate images that visually represent the meaning of song's words.<br>These images are then animated and synchronized with the rhythm and mood of the music to create a unique video.",
    },
  ],
  pricing: [
    {
      title: "Is there a free trial?",
      content:
        "Yes! With our “Free” plan, you can generate a short preview video of your song at no cost.<br>If you're happy with the result, you can upgrade your subscription or purchase additional minutes.",
    },
    {
      title: "Can I just generate one video without subscription?",
      content:
        "Yes, you can! Simply select the “Free” plan and purchase additional minutes if needed.<br>We understand you may not need to generate videos every month 👍",
    },
    {
      title: "Can I cancel my subscription?",
      content:
        "Yes, you can cancel your subscription at any time.<br>Your subscription will remain active until the end of your current billing cycle.",
    },
    {
      title: "Can I purchase additional minutes?",
      content:
        "Yes, you can. If you run out of minutes, you can either upgrade your plan or purchase additional minutes at any time.",
    },
    {
      title: "Will my unused minutes roll over into the next month or billing cycle?",
      content:
        "Minutes obtained through a subscription do <b>not</b> roll over into the next month—they reset and renew each month based on your plan’s activation date.<br>Additional minutes purchased separately from a subscription expire <b>one year</b> after purchase.",
    },
    {
      title: "Do you offer refunds?",
      content:
        "No, we do <b>not</b> offer refunds. Due to the nature of AI-generated content and the computational costs involved, all purchases are final.<br>Exceptions may apply, please check our Terms of Service.",
    },
  ],
};

export const enterprisePlan = {
  id: "enterprise",
  name: "Enterprise",
  price: "Get a quote",
  callToAction: "Contact Sales",
  // TODO:
  redirect: "/dashboard/help?subject=I+am+interested+in+the+enterprise+plan",
  features: [
    { text: "Everything in Pro", plusIcon: true },
    { text: "Custom quote per minute" },
    { text: "API access" },
    {
      text: "Access to a professional video editor team",
      detail: "our team of video editors can assist you in creating your videos",
    },
  ],
};

export const freePlan = {
  id: "free",
  name: "Free",
  price: "$0",
  period: "/month",
  callToAction: "Get started for free",
  redirect: "/dashboard",
  useCase: "1 short video monthly",
  features: [
    {
      text: "30 seconds of songs / month",
      detail:
        "additional minutes <span class='whitespace-nowrap'><span class='line-through font-semibold text-muted-color'>$20</span> <span class='font-black'>$15</span> each</span>",
      note: 2,
    },
    { text: "Unlimited video regeneration", note: 3 },
    { text: "1080p, 24 FPS" },
    { text: "Watermark-free", excluded: true },
    { text: "Commercial use", excluded: true },
    { text: "Davinci Resolve export", excluded: true },
    { text: "Final Cut X export", excluded: true },
  ],
  comingSoon: [
    { text: "Adobe Premiere export", excluded: true },
    { text: "Upload your own images", excluded: true },
  ],
};

export const paidPlans = [
  {
    id: "starter",
    name: "Starter",
    price: "$69",
    priceDiscount: "-20%",
    priceFull: "$89",
    period: "/month",
    redirect: "/dashboard/billing?subscribe=starter",
    useCase: "1-2 full-length videos monthly",
    features: [
      {
        text: "5 minutes of songs / month",
        detail:
          "additional minutes <span class='whitespace-nowrap'><span class='line-through font-semibold text-muted-color'>$13</span> <span class='font-black'>$11</span> each</span>",
        note: 2,
      },
      { text: "Unlimited video regeneration", note: 3 },
      { text: "1080p, 24 FPS" },
      { text: "Watermark-free" },
      { text: "Commercial use" },
      { text: "Davinci Resolve export" },
      { text: "Final Cut X export" },
    ],
    comingSoon: [{ text: "Adobe Premiere export" }, { text: "Upload your own images" }],
  },
  {
    id: "creator",
    name: "Creator",
    price: "$99",
    priceDiscount: "-20%",
    priceFull: "$129",
    period: "/month",
    highlight: true,
    redirect: "/dashboard/billing?subscribe=creator",
    useCase: "2-3 full-length videos monthly",
    features: [
      {
        text: "10 minutes of songs / month",
        detail:
          "additional minutes <span class='whitespace-nowrap'><span class='line-through font-semibold text-muted-color'>$11</span> <span class='font-black'>$9</span> each</span>",
        note: 2,
      },
      { text: "Unlimited video regeneration", note: 3 },
      { text: "1080p, 24 FPS" },
      { text: "Watermark-free" },
      { text: "Commercial use" },
      { text: "Davinci Resolve export" },
      { text: "Final Cut X export" },
    ],
    comingSoon: [{ text: "Adobe Premiere export" }, { text: "Upload your own images" }],
  },
  {
    id: "pro",
    name: "Pro",
    price: "$199",
    priceDiscount: "-20%",
    priceFull: "$249",
    period: "/month",
    redirect: "/dashboard/billing?subscribe=pro",
    useCase: "6-7 full-length videos monthly",
    features: [
      {
        text: "25 minutes of songs / month",
        detail:
          "additional minutes <span class='whitespace-nowrap'><span class='line-through font-semibold text-muted-color'>$9</span> <span class='font-black'>$7</span> each</span>",
        note: 2,
      },
      { text: "Unlimited video regeneration", note: 3 },
      { text: "1080p, 24 FPS" },
      { text: "Watermark-free" },
      { text: "Commercial use" },
      { text: "Davinci Resolve export" },
      { text: "Final Cut X export" },
    ],
    comingSoon: [{ text: "Adobe Premiere export" }, { text: "Upload your own images" }],
  },
];
