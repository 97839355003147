import type { Checkout, CreditGrant, Invoice, Manage, Scene, User, Video, VideoSimple } from "@/api.d";

import { auth0 } from "@/auth0";

export const API_BASE_URL = `${import.meta.env.VITE_API_BASE_URL}/v1`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function fetchProtected(url: string, options: any = {}): Promise<Response> {
  const token = await auth0.getAccessTokenSilently();

  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: "Bearer " + token,
    },
  });
}

export async function getGuestVideo(videoId: string): Promise<Video> {
  const url = `${API_BASE_URL}/gvideos/${videoId}`;
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export function getUploadUrl(
  authenticate?: boolean,
  start?: number | null,
  end?: number | null,
  precision = 3,
): string {
  const url = authenticate ? `${API_BASE_URL}/videos` : `${API_BASE_URL}/gvideos`;
  if (start !== undefined && start !== null && end !== undefined && end !== null) {
    return `${url}?start=${start.toFixed(precision)}&end=${end.toFixed(precision)}`;
  }
  return url;
}

export async function regenerateVideo(videoId: string, data: object): Promise<Video> {
  const url = `${API_BASE_URL}/videos/${videoId}/regenerate`;
  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function generateFullVideo(videoId: string): Promise<Video> {
  const url = `${API_BASE_URL}/videos/${videoId}/generate`;
  const response = await fetchProtected(url, {
    method: "POST",
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function contact(message: string, videoId?: string): Promise<void> {
  const url = `${API_BASE_URL}/contact`;

  const params = videoId ? { message, video_id: videoId } : { message };

  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function contactGuest(name: string, email: string, message: string): Promise<void> {
  const url = `${API_BASE_URL}/gcontact`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, email, message }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getCurrentUser(): Promise<User> {
  const url = `${API_BASE_URL}/me`;
  const response = await fetchProtected(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function deleteVideo(videoId: string): Promise<Video> {
  const url = `${API_BASE_URL}/videos/${videoId}`;
  const response = await fetchProtected(url, {
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getVideo(videoId: string): Promise<Video> {
  const url = `${API_BASE_URL}/videos/${videoId}`;
  const response = await fetchProtected(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getVideos(): Promise<VideoSimple[]> {
  const url = `${API_BASE_URL}/videos`;
  const response = await fetchProtected(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function updateVideo(videoId: string, data: object): Promise<Video> {
  const url = `${API_BASE_URL}/videos/${videoId}`;
  const response = await fetchProtected(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function sendFullVideoFeedback(videoId: string, comment: string): Promise<Video> {
  const url = `${API_BASE_URL}/videos/${videoId}/feedback`;
  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ comment }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function createScenes(videoId: string): Promise<Scene[]> {
  const url = `${API_BASE_URL}/videos/${videoId}/scenes`;
  const response = await fetchProtected(url, {
    method: "POST",
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getScenes(videoId: string): Promise<Scene[]> {
  const url = `${API_BASE_URL}/videos/${videoId}/scenes`;
  const response = await fetchProtected(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function generateSceneClip(videoId: string, sceneId: string, data: object): Promise<Scene> {
  const url = `${API_BASE_URL}/videos/${videoId}/scenes/${sceneId}/generate`;
  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function regenerateSceneImage(videoId: string, sceneId: string, data: object): Promise<Scene> {
  const url = `${API_BASE_URL}/videos/${videoId}/scenes/${sceneId}/regenerate`;
  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function updateScene(videoId: string, sceneId: string, data: object): Promise<Scene> {
  const url = `${API_BASE_URL}/videos/${videoId}/scenes/${sceneId}`;
  const response = await fetchProtected(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function createCheckout(data: object): Promise<Checkout> {
  const url = `${API_BASE_URL}/payments/checkout`;
  const response = await fetchProtected(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function createManage(): Promise<Manage> {
  const url = `${API_BASE_URL}/payments/manage`;
  const response = await fetchProtected(url, {
    method: "POST",
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getCreditGrantHistory(): Promise<CreditGrant[]> {
  const url = `${API_BASE_URL}/payments/history`;
  const response = await fetchProtected(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function getInvoice(paymentId: string): Promise<Invoice> {
  const url = `${API_BASE_URL}/payments/${paymentId}/invoice`;
  const response = await fetchProtected(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}
