<script setup lang="ts">
import Footer from "@/components/common/Footer.vue";
import TopBar from "@/components/dashboard/TopBar.vue";

import { useRouter } from "vue-router";

const router = useRouter();
</script>

<template>
  <div class="min-h-screen flex flex-col">
    <TopBar />

    <main class="flex-grow overflow-y-auto overflow-x-hidden">
      <div class="pt-8 pb-16 px-5 mx-auto">
        <slot>
          <router-view />
        </slot>
      </div>

      <Footer v-if="!router.currentRoute.value.meta.noFooter" />
    </main>
  </div>
</template>

<style scoped>
main {
  --fixed-topbar-height: 73px;
  height: calc(100vh - var(--fixed-topbar-height));
}
</style>
